import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import * as Sentry from '@sentry/core'
import { useSnackbar } from 'notistack'
import type { DialogProps } from '../Dialog'
import Button from '../Button'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../Dialog'
import Typography from '../Typography'
import type { DropdownSelectOption } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'
import Grid from '../Grid'
import InputField from '../InputField'

export const FEATURE_OPTIONS: DropdownSelectOption[] = [
  { label: 'Views - Area', value: 'quotes.views.area' },
  { label: 'Views - Substrate', value: 'quotes.views.substrate' },
  { label: 'Views - WorkOrder', value: 'quotes.views.workOrder' },
  { label: 'Emails - To Staff', value: 'quotes.emails.toStaff' },
  { label: 'Emails - Reminders', value: 'quotes.emails.reminders' },
  { label: 'Emails - Attach PDF', value: 'quotes.emails.attachPdf' },
  { label: 'Emails - Thank You', value: 'quotes.emails.acceptThankYou' },
  { label: 'Emails - Enable To Field', value: 'quotes.emails.allowToField' },
  { label: 'Emails - Enable AddressBook emails', value: 'quotes.emails.hasAddressBook' },
  { label: 'Emails - Settings Communications', value: 'settingsCommunications.enabled' },
  { label: 'Sidebar - Activity', value: 'quotes.sidebar.activity.enabled' },
  { label: 'Sidebar - Details', value: 'quotes.sidebar.details.enabled' },
  { label: 'Sidebar - Calculation', value: 'quotes.sidebar.calculation.enabled' },
  { label: 'Sidebar - Presentation', value: 'quotes.sidebar.presentation.enabled' },
  { label: 'Sidebar - Terms', value: 'quotes.sidebar.terms.enabled' },
  { label: 'Sidebar - Advanced', value: 'quotes.sidebar.advanced.enabled' },
  { label: 'Sidebar - Source/Deal', value: 'quotes.sidebar.advanced.source' },
  {
    label: 'Sidebar - Advanced - Detailed Breakdown',
    value: 'quotes.sidebar.advanced.displayOptions.showDetailedBreakdown'
  },
  {
    label: 'Sidebar - Advanced - Stack Area Substrates',
    value: 'quotes.sidebar.advanced.displayOptions.stackSubstrates'
  },
  {
    label: 'Sidebar - Advanced - Hide Materials Cost',
    value: 'quotes.sidebar.advanced.displayOptions.hideWorkOrderMaterialCost'
  },
  {
    label: 'Contacts - Address Suggestions',
    value: 'quotes.addressValidation.enabled'
  },
  {
    label: 'Contacts - Contact Sources',
    value: 'contactLeadSources.enabled'
  },
  {
    label: 'Misc - Material Cost',
    value: 'quotes.materialCost.enabled'
  },
  {
    label: 'Misc - Split Tax Rate',
    value: 'quotes.splitTaxRate.enabled'
  },
  {
    label: 'Misc - Groups',
    value: 'quotes.groups.enabled'
  },
  {
    label: 'Misc - Acceptance Date Change',
    value: 'quotes.changeAcceptanceDate'
  },
  {
    label: 'Misc - Scheduled Date',
    value: 'quotes.scheduledDate'
  },
  {
    label: 'Misc - Secondary Contact',
    value: 'quotes.secondaryContact.enabled'
  },
  {
    label: 'Misc - Request Google Review (Beta)',
    value: 'requestGoogleReview.enabled'
  },
  {
    label: 'Misc - Payment Requests',
    value: 'paymentRequests.enabled'
  },
  {
    label: 'Misc - Product Order Form',
    value: 'quotes.productOrderForm'
  },
  {
    label: 'Misc - Tags',
    value: 'quotes.tags.enabled'
  },
  {
    label: 'Misc - Multiple Invoices',
    value: 'quotes.multipleInvoices'
  },
  {
    label: 'Misc - Convert to Invoice',
    value: 'quotes.convertToInvoice'
  },
  {
    label: 'Misc - Company Override Quote Types',
    value: 'quotes.quoteTypeCompanyOverride'
  },
  {
    label: 'Misc - Bulk Materials Editing',
    value: 'quotes.bulkMaterialsEditing'
  },
  {
    label: 'Misc - Editable Area Totals',
    value: 'quotes.editableAreaTotals'
  },
  {
    label: 'Misc - Custom ID Sequence',
    value: 'quotes.customIDSequence'
  },
  {
    label: 'Misc - Hide All Quote Type',
    value: 'quotes.hideAllQuoteType'
  },
  {
    label: 'Misc - Show Switch Button',
    value: 'quotes.areaSubstrates.showSwitchButton'
  },
  {
    label: 'Misc - Negative Values in Number Inputs',
    value: 'allowNegativeNumberInputs'
  },
  {
    label: 'Misc - Quote Sources',
    value: 'quotes.quoteLeadSources.enabled'
  },
  {
    label: 'Misc - Custom Quote Table Columns',
    value: 'customQuoteTableColumns'
  },
  {
    label: 'Line Items - Line Item Media in Options',
    value: 'quotes.lineItemMediaOptions.enabled'
  },
  {
    label: 'Line Items - Convert to Line Item',
    value: 'quotes.convertToLineItem'
  },
  {
    label: 'Line Items - Allow Hours Per Unit Line Items',
    value: 'quotes.hoursPerUnitLineItems'
  },
  {
    label: 'Advanced Rate Options - Enabled',
    value: 'quotes.areaSubstrates.advancedOptions.enabled'
  },
  {
    label: 'Advanced Rate Options - Work Order Only',
    value: 'quotes.areaSubstrates.advancedOptions.workOrderOnly'
  },
  {
    label: 'Advanced Rate Options - Hide Quantities (Estimate)',
    value: 'quotes.areaSubstrates.advancedOptions.hideQuantity'
  },
  {
    label: 'Advanced Rate Options - Hide Quantities (Work Order)',
    value: 'quotes.areaSubstrates.advancedOptions.hideQuantityOnWorkOrder'
  },
  {
    label: 'Advanced Rate Options - Show Coats',
    value: 'quotes.areaSubstrates.advancedOptions.showCoats'
  },
  {
    label: 'Advanced Rate Options - Show Product',
    value: 'quotes.areaSubstrates.advancedOptions.showProduct'
  },
  {
    label: 'Advanced Rate Options - Show Price',
    value: 'quotes.areaSubstrates.advancedOptions.showPrice'
  },
  {
    label: 'Advanced Rate Options - Show Prep Hours',
    value: 'quotes.areaSubstrates.advancedOptions.showPrep'
  },
  {
    label: 'Advanced Rate Options - Exclude Tax',
    value: 'quotes.areaSubstrates.advancedOptions.excludeTax'
  },
  {
    label: 'Advanced Rate Options - Exclude Discount',
    value: 'quotes.areaSubstrates.advancedOptions.excludeDiscount'
  },
  {
    label: 'Advanced Rate Options - Hourly Rate',
    value: 'quotes.areaSubstrates.advancedOptions.hourlyRate'
  },
  {
    label: 'Advanced Rate Options - Default Rate Dimensions',
    value: 'quotes.areaSubstrates.advancedOptions.defaultDimensions'
  },
  {
    label: 'Advanced Rate Options - Prep Hours',
    value: 'quotes.areaSubstrates.advancedOptions.prepHours'
  },
  {
    label: 'Advanced Rate Options - Use Inches (Beta)',
    value: 'quotes.areaSubstrates.advancedOptions.useInches'
  },
  {
    label: 'Advanced Rate Options - Cubic Feet (Beta)',
    value: 'quotes.areaSubstrates.advancedOptions.cubicFeet'
  },
  {
    label: 'Advanced Rate Options - Multiple Product Rates',
    value: 'quotes.areaSubstrates.multiProductRates'
  },
  {
    label: 'Client Settings - Deleted Estimates Section',
    value: 'quotes.deletedQuotes.enabled'
  },
  {
    label: 'Markup Materials(Option Tile Multi-select)',
    value: 'markupMaterials.enabled'
  },
  {
    label: 'Invoices - Enabled',
    value: 'invoices.enabled'
  },
  {
    label: 'Invoices - Invoice Terms',
    value: 'invoiceTerms.enabled'
  },
  {
    label: 'Media - Enabled',
    value: 'media.enabled'
  },
  {
    label: 'Media - S3 Image Uploads',
    value: 's3ImageUpload.enabled'
  },
  {
    label: 'Import Quote Image (Beta) - Enabled',
    value: 'importQuoteImage.enabled'
  },
  {
    label: 'Import Quote Image (Beta) - OCR Imported Images',
    value: 'importQuoteImage.ocr.enabled'
  },
  {
    label: 'Import Quote Image (Beta) - Import Image Only Option',
    value: 'importQuoteImage.importOnly.enabled'
  },
  {
    label: 'Presentations - Enabled',
    value: 'presentation.enabled'
  },
  {
    label: 'Presentations - About Your Estimator Section',
    value: 'presentation.aboutYourEstimator'
  },
  {
    label: 'Presentations - Use Job Identifier in Cover Section subtitle',
    value: 'presentation.jobIDInCoverSection'
  },
  {
    label: 'Payment Options (Beta) - Enabled',
    value: 'paymentOptions.enabled'
  },
  {
    label: 'Payment Options (Beta) - Surcharging',
    value: 'surcharge'
  },
  {
    label: 'Reports - Enabled',
    value: 'reports.enabled'
  },
  {
    label: 'Reports - Payments',
    value: 'reports.payments'
  },
  {
    label: 'Reports - Payments Graphs',
    value: 'reports.paymentsGraphs'
  },
  {
    label: 'Reports - Financing',
    value: 'reports.financing'
  },
  {
    label: 'Reports - Financing Graphs',
    value: 'reports.financingGraphs'
  },
  {
    label: 'Reports - Estimates',
    value: 'reports.estimates'
  },
  {
    label: 'Reports - Estimates Graphs',
    value: 'reports.estimatesGraphs'
  },
  {
    label: 'Reports - Estimators',
    value: 'reports.estimators'
  },
  {
    label: 'Reports - Estimators Graphs',
    value: 'reports.estimatorsGraphs'
  },
  {
    label: 'Reports - Activity',
    value: 'reports.activity'
  },
  {
    label: 'Reports - Activity Graphs',
    value: 'reports.activityGraphs'
  },
  {
    label: 'Reports - Advanced',
    value: 'reports.advanced'
  },
  {
    label: 'Reports - Advanced Graphs',
    value: 'reports.advancedGraphs'
  },
  {
    label: 'Editor - Show Color Buttons',
    value: 'editorOptions.textColor'
  },
  {
    label: 'Conversations - Enabled',
    value: 'quotes.conversations.enabled'
  },
  {
    label: 'Follow-Ups - Enabled',
    value: 'quotes.followUps.enabled'
  },
  {
    label: 'Follow-Ups - SMS Follow-Ups',
    value: 'quotes.followUps.sendText'
  },
  {
    label: 'Notifications - Enabled',
    value: 'notifications.enabled'
  },
  {
    label: 'Notifications - Statistical Digest',
    value: 'notifications.statsDigest'
  },
  {
    label: 'Notifications - Quote Viewed',
    value: 'notifications.quoteViewed'
  },
  {
    label: 'Integrations - Enabled',
    value: 'integrations.enabled'
  },
  {
    label: 'Integrations - Settings/Company Based Integrations',
    value: 'settingsIntegrations.enabled'
  },
  {
    label: 'Integrations - Options - Stripe',
    value: 'integrations.providers.stripe'
  },
  {
    label: 'Integrations - Options - Search All People PipelineCRM',
    value: 'pipelineKey.peopleSearch'
  },
  {
    label: 'Integrations - Options - Xero',
    value: 'integrations.providers.xero'
  },
  {
    label: 'Integrations - Options - ZenDesk Sell',
    value: 'integrations.providers.getbase'
  },
  {
    label: 'Integrations - Options - Wisetack',
    value: 'integrations.providers.wisetack'
  },
  {
    label: 'Integrations - Options - Wisetack Staged Payouts',
    value: 'wisetackStagedPayouts'
  },
  {
    label: 'Dashboard - Sections - Statistics',
    value: 'dashboard.statistics'
  },
  {
    label: 'Quote List - Show On Hold Tab',
    value: 'quoteList.showOnHoldTab'
  },
  {
    label: 'SMS - Enabled',
    value: 'sms.enabled'
  },
  {
    label: 'Templates - Enabled',
    value: 'quotes.templates.enabled'
  },
  {
    label: 'Templates - Smart Templates',
    value: 'quotes.smartTemplate'
  },
  {
    label: 'Templates - Multi Quote-Type Smart Templates',
    value: 'quotes.multiQuoteTemplates'
  },
  {
    label: 'LogRocket - Enabled',
    value: 'logRocket.enabled'
  },
  {
    label: 'LogRocket - Customer View LogRocket',
    value: 'logRocket.customerView'
  },
  {
    label: 'Billing - Enabled',
    value: 'billing.enabled'
  },
  {
    label: 'Team - Enabled',
    value: 'team.enabled'
  },
  {
    label: 'Team - Sections - Crew',
    value: 'team.crew'
  },
  {
    label: 'Team - Sections - Viewer',
    value: 'team.viewer'
  },
  {
    label: 'Team - Permissions - Custom Permissions',
    value: 'team.permissions.enabled'
  },
  {
    label: 'Team - Invoices - Crew Invoice Auto-Assignment',
    value: 'team.crewInvoiceAutoAssign.enabled'
  },
  {
    label: 'Font Scaling - Enabled',
    value: 'fontScaling.enabled'
  },
  {
    label: 'ElasticSearch - Enabled',
    value: 'database.elasticsearch.enabled'
  },
  {
    label: 'Partner Content - Enabled',
    value: 'partnerContent.enabled'
  },
  {
    label: 'Partner Content - Sherwin Williams - Presentation Pages',
    value: 'partnerContent.sherwinWilliams.presentationPages.enabled'
  },
  {
    label: 'Partner Content - Sherwin Williams - Products',
    value: 'partnerContent.sherwinWilliams.products.enabled'
  },
  {
    label: 'Partner Content - Sherwin Williams - Product Ordering',
    value: 'partnerContent.sherwinWilliams.productOrdering.enabled'
  },
  {
    label: 'Partner Content - Benjamin Moore - Presentation Pages',
    value: 'partnerContent.benjaminMoore.presentationPages.enabled'
  },
  {
    label: 'Partner Content - Benjamin Moore - Products',
    value: 'partnerContent.benjaminMoore.products.enabled'
  },
  {
    label: 'Partner Content - Benjamin Moore - Product Ordering',
    value: 'partnerContent.benjaminMoore.productOrdering.enabled'
  }
]
const valueOptions: DropdownSelectOption[] = [
  { label: 'Enable', value: true },
  { label: 'Disable', value: false }
]

const useStyles = makeStyles<Theme, FeatureSelectDialogProps>(
  (_theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {
      height: '500px',
      overflow: 'auto'
    },
    cancel: {},
    confirm: {}
  }),
  { name: 'FeatureSelectDialog' }
)

export interface FeatureSelectDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  onConfirm: (featureList: string[], value: boolean, email: string) => Promise<void>
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
}

function FeatureSelectDialog(props: FeatureSelectDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<boolean>(true)
  const [featureList, setFeatureString] = useState<string[]>([])
  const [email, setEmail] = useState<string>('')

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'Cancel'}
    </Button>
  )

  return (
    <Dialog {...baseDialogProps}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Typography variant="h2">Feature Update Dialog</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid item xs={12}>
          <InputField
            name="email"
            label="Confirmation Email"
            sublabel="Enter the email address to send the confirmation to when complete"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            fullWidth
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <DropdownSelect
            name="features"
            label="Features"
            sublabel="Select the features to enable or disable for current clients"
            searchable
            placeholder={{
              selectPlaceholder: 'No Features'
            }}
            variant="multi"
            options={FEATURE_OPTIONS}
            value={FEATURE_OPTIONS.filter((option) => {
              if (!featureList.length) return false
              return featureList.includes(option.value)
            })}
            onChange={(option: DropdownSelectOption[]) => {
              const value = option.map((vals) => (vals?.value ? vals.value : vals))
              setFeatureString(value)
            }}
            fullWidth
          />
        </Grid>
        <br />
        <Grid item xs={4}>
          <DropdownSelect
            name="value"
            label="Enable/Disable"
            placeholder={{
              selectPlaceholder: 'Enable/Disable'
            }}
            variant="single"
            options={valueOptions}
            value={valueOptions.find((option) => option.value === value)}
            onChange={(option: DropdownSelectOption) => {
              setValue(option.value)
            }}
            fullWidth
          />
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          type="submit"
          form="feature-select-dialog-form"
          variant={'contained'}
          disabled={isLoading || !featureList.length || !email.length}
          data-testid="feature-select-dialog-confirm-button"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleSubmit() {
    setIsLoading(true)
    try {
      onConfirm(featureList, value, email)
    } catch (error) {
      Sentry.captureException(error)
      enqueueSnackbar('error', { variant: 'error' })
    }
    setIsLoading(false)
  }
}

export default FeatureSelectDialog
