import { Card, CardContent, Grid, styled } from '@material-ui/core'
import { Button, ConfirmationDialog, FloatingMenu, MenuItem, Select, Typography, useDialogs } from '@ui/paintscout'
import { FieldArray } from 'formik'
import React, { useEffect, useState } from 'react'
import { getHighestRole } from '@paintscout/util'
import UserImportExport from './UserImportExport'
import { useFormikContext } from 'formik'
import type { OptionsDocument, UserPreferencesDocument } from 'paintscout'
import type { User } from '@paintscout/api'
import { DeactivateUserDialog } from '@ui/react-quote/dialogs'

export interface UserAdministrationFormProps {
  section?: string

  onSectionChange?: (section: string) => any
}

export default function UserAdministrationForm({ section = 'general', onSectionChange }: UserAdministrationFormProps) {
  const [menuValue, setMenuValue] = useState(section)

  // update if url changes
  useEffect(() => {
    setMenuValue(section)
  }, [section])

  function handleMenuItemClick(item) {
    if (onSectionChange) {
      onSectionChange(item.key)
    }
    setMenuValue(item.key)
  }

  const menuItems = [
    { key: 'general', label: 'General' },
    { key: 'importExport', label: 'Import/Export' }
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={2}>
        <FloatingMenu
          value={menuValue}
          defaultValue="general"
          items={menuItems}
          onItemClick={handleMenuItemClick}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={10}>
        <Card>
          <CardContent>
            {menuValue === 'general' && <GeneralForm />}
            {menuValue === 'importExport' && <UserImportExport />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

function GeneralForm() {
  const { openDialog, dismissDialog } = useDialogs()
  const { values, setFieldValue, submitForm } = useFormikContext<{
    options: OptionsDocument
    preferences: UserPreferencesDocument
    user: User
  }>()

  return (
    <>
      <Typography variant="h5">General</Typography>
      <SectionContent>
        <Grid container spacing={2} style={{ paddingBottom: '5px' }}>
          <Grid item xs={12}>
            <Typography variant="body1">
              User is currently: {values.user.app_metadata.active ? 'Active' : 'Inactive'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                if (values.user.app_metadata.active) {
                  openDialog(ConfirmationDialog, {
                    title: 'Deactivate User',
                    message:
                      'Are you sure you want to deactivate this user? Clients chargebee subscription will be updated to exclue user if they are a billing client.',
                    scary: true,
                    onCancel: dismissDialog,
                    onConfirm: () => {
                      dismissDialog()
                      openDialog(DeactivateUserDialog, {
                        user: values.user,
                        onClose: dismissDialog,
                        onConfirm: () => {
                          window.location.reload()
                        }
                      })
                    }
                  })
                } else {
                  openDialog(ConfirmationDialog, {
                    title: 'Activate User',
                    message:
                      'Are you sure you want to activate this user? Clients chargebee subscription will be updated to include new user if they are a billing client.',
                    scary: true,
                    onCancel: dismissDialog,
                    onConfirm: () => {
                      dismissDialog()
                      setFieldValue('user.app_metadata.active', true)
                      submitForm()
                    }
                  })
                }
              }}
            >
              {values.user.app_metadata.active ? 'Deactivate' : 'Activate'}
            </Button>
          </Grid>
        </Grid>
      </SectionContent>
      <Typography variant="h5">Role</Typography>
      <SectionContent>
        <Grid container>
          <FieldArray
            name="user.app_metadata.roles"
            render={(arrayHelpers) => (
              <Select
                margin="dense"
                value={getHighestRole(arrayHelpers.form.values.user.app_metadata.roles)}
                variant="outlined"
                onChange={(e) => {
                  arrayHelpers.form.setFieldValue('user.app_metadata.roles', [e.target.value])
                }}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem value="crew">Crew</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
              </Select>
            )}
          />
        </Grid>
      </SectionContent>
    </>
  )
}

const SectionContent = styled('div')({
  paddingLeft: 20,
  paddingRight: 20
})
