import { useMediaQuery, AlertDialog, useDialogs, FeatureSelectDialog } from '@ui/paintscout'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'
import type { Theme } from '@material-ui/core'
import { Card, makeStyles, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ClientSearchTable from '../../components/ClientSearchTable'
import ErrorMessage from '../../components/ErrorMessage'
import NewClientButton from '../../components/NewClientButton'
import { gql } from 'apollo-boost'
import { NavHeader } from '../../components/NavDrawer'

import type { ValueFilter } from 'json-to-lucene'
import { jsonToLucene } from 'json-to-lucene'
import FileSaver from 'file-saver'
import { useSearchClientsLazyQuery } from '@paintscout/api'
import ClientSearchBar, { getFiltersFromLocation } from '../../components/ClientSearchBar/ClientSearchBar'
import { setUrlQuery, getUrlQuery } from 'shared/util/routing'
import moment from 'moment'
import ClientExportSelectDialog from '@paintscout/ui/src/dialogs/ClientExportSelectDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  searchBar: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      gridColumn: '1 / 3'
    }
  },
  searchContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    },
    '& input': {
      minWidth: 400,
      [theme.breakpoints.down('md')]: {
        minWidth: 260
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset'
      }
    }
  },
  filterChips: {
    '&$filterChips': {
      gridRow: 3,
      gridColumn: '1 / -1',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(-1)
    }
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }
}))

export interface SearchProps {
  text: string
  filters: ValueFilter[]
  isInitialSearch?: boolean
}

export interface UrlParams {
  sort: string
  page: number
  search: string
}

export default function SearchClients() {
  const classes = useStyles({})
  const location = useLocation<{ page: number }>()
  const history = useHistory()
  const urlParams = getUrlQuery<UrlParams>({ location })
  const sort = urlParams.sort || '-updated_date'
  const { enqueueSnackbar } = useSnackbar()
  const smDown = useMediaQuery('sm')
  const { openDialog, dismissDialog } = useDialogs()
  const apolloClient = useApolloClient()
  const limit = 50
  const [page, setPage] = useState(location.state?.page ?? 1)
  const [filters, setFilters] = useState(getFiltersFromLocation(location))
  const [query, setQuery] = useState('') // will get populated from ContactSearchBar
  const [searchQuery, setSearchQuery] = useState('') // Raw query to compare for exact search matches

  const [search, { loading, data, error, fetchMore }] = useSearchClientsLazyQuery({
    variables: {
      query,
      limit
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (newData) => {
      // reset page back to 1 if user refreshes the page (cache is reset)
      if (
        page * limit > newData?.searchClients?.rows.length &&
        newData?.searchClients?.rows.length !== newData?.searchClients?.total_rows
      ) {
        setPage(1)
      }
    }
  })

  async function handleClientFeatureUpdate({ text, filters }: SearchProps) {
    const query = getQuery({ query: text ?? '', filters })
    openDialog(FeatureSelectDialog, {
      onConfirm: async (featureList: string[], value: boolean, email: string) => {
        const { data } = await apolloClient.mutate({
          mutation: gql`
            mutation ($query: String!, $featureList: String!, $value: Boolean!, $email: String!) {
              updateClientFeatures(query: $query, featureList: $featureList, value: $value, email: $email)
            }
          `,
          variables: {
            value,
            query,
            featureList: featureList.join(','),
            email
          }
        })

        if (data.updateClientFeatures) {
          enqueueSnackbar('Client feature update started successfully, you will receive an email when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Client feature update failed', {
            variant: 'error'
          })
        }
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  async function handleClientExport({ text, filters }: SearchProps) {
    openDialog(ClientExportSelectDialog, {
      onConfirm: async (exportType: string, includeInactive: boolean) => {
        dismissDialog()
        const query = getQuery({ query: text ?? '', filters })
        const fileName = `Clients ${moment(Date.now())}.csv`
        const { data } = await apolloClient.query({
          query: gql`
            query ($query: String!, $exportType: String!, $includeInactive: Boolean!, $sort: [String!]) {
              exportClients(query: $query, exportType: $exportType, includeInactive: $includeInactive, sort: $sort) {
                csv
                rows
              }
            }
          `,
          variables: {
            query,
            exportType,
            includeInactive,
            // Need 2 sort params for tiebreaker, keep sort from quoteList if present else same default
            sort: ['created']
          }
        })

        if (data.exportClients.rows) {
          FileSaver.saveAs(new Blob([data.exportClients.csv]), fileName)
        } else {
          openDialog(AlertDialog, {
            message: 'No rows were found to export for this search query.',
            onConfirm: () => {
              dismissDialog()
            }
          })
        }
      },
      onCancel: dismissDialog
    })
  }

  function handleClientSearch({ text, filters, isInitialSearch }: SearchProps) {
    const query = getQuery({ query: text ?? '', filters })
    setSearchQuery(text)
    if (!isInitialSearch) {
      setPage(1)
    }
    search({
      variables: {
        query,
        sort: [sort, '-created'],
        limit
      }
    })
  }

  function getQuery({ query = '', filters }): string {
    let fullQuery = jsonToLucene({ query, filters }) || '*:*'
    if (fullQuery === '') {
      fullQuery = '*:*'
    }
    setUrlQuery({
      location,
      params: { search: query || null, filters },
      replaceParams: true
    })

    setQuery(fullQuery)
    setFilters(filters)

    return fullQuery
  }

  useEffect(() => {
    history.replace({
      ...history.location,
      state: { page }
    })
  }, [page, history])

  // show error toast
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Unable to search Clients`, { variant: 'error' })
    }
  }, [error, enqueueSnackbar])

  const handleRequestSort = (ev: React.MouseEvent, name: string, direction: 'asc' | 'desc') => {
    const newSort = `${direction === 'desc' ? '-' : ''}${name}`

    setPage(1)
    setUrlQuery({ location, params: { sort: newSort } })

    search({
      variables: {
        query: query ?? '*:*',
        sort: [newSort, '-created']
      }
    })
  }

  const { total_rows = 0, rows = [] } = data?.searchClients ?? {}

  return (
    <div className={classes.root}>
      <NavHeader title="Clients" />
      <div className={classes.headerDiv}>
        <NewClientButton />
        <Typography variant="h6">Total Count: {total_rows}</Typography>
      </div>

      <ClientSearchBar
        classes={{
          clientSearchRoot: classes.searchBar,
          container: classes.searchContainer,
          filterChips: classes.filterChips
        }}
        fullWidth={smDown ? true : false}
        loading={loading}
        filters={filters}
        showChips={true}
        onUpdate={async (query, filters) => {
          await handleClientFeatureUpdate({
            text: query,
            filters
          })
        }}
        onExport={async (query, filters) => {
          await handleClientExport({
            text: query,
            filters
          })
        }}
        onSearch={(query, filters, isInitialSearch) => {
          handleClientSearch({
            text: query,
            filters,
            isInitialSearch
          })
        }}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <Card>
        <ClientSearchTable
          clients={
            loading
              ? []
              : [
                  ...rows.reduce((acc, row) => {
                    // if we find a more exact match bubble it to top of results
                    if (row?.name?.toLowerCase() === searchQuery?.toLowerCase()) {
                      return [{ ...row }, ...acc]
                    }
                    return [...acc, { ...row }]
                  }, [])
                ]
          }
          loading={loading}
          rowsPerPage={limit}
          sort={sort}
          page={page}
          pages={Math.ceil(total_rows / limit)}
          onPageChange={(newPage) => setPage(newPage)}
          onRequestSort={handleRequestSort}
          onFetchNextPage={() => {
            fetchMore({
              variables: {
                query: query ?? '*:*',
                limit,
                bookmark: data.searchClients.bookmark
              },
              updateQuery(prev, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return prev
                }

                return {
                  ...prev,
                  searchClients: {
                    ...prev.searchClients,
                    bookmark: fetchMoreResult.searchClients.bookmark,
                    rows: [...prev.searchClients.rows, ...fetchMoreResult.searchClients.rows]
                  }
                }
              }
            })
          }}
        />
      </Card>
    </div>
  )
}
